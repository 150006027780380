.loadingLogo {
    width: 90vw;
    margin: auto;
    height: 70svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.loading-img {
    -webkit-animation: mover .7s infinite alternate;
    animation: mover .7s infinite alternate;
}

img.loading-img {
    -webkit-animation: mover .7s infinite alternate;
    animation: mover .7s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}