html:lang(ar) {
  direction: ltr !important;
}

.text-primary {
  color: #294595 !important;
}

li {
  list-style: none;
}

.border-button {
  border: solid 1px #08223c !important;
  font-size: 3rem !important;
}

.navigation {
  position: relative;
  display: inline-block;
}

.navigation2 {
  position: relative;
  display: inline-block;
}

.navigation-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px !important;
  height: 300px;
  overflow-y: scroll;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation-content-last {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 15vw !important;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation-content-last2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 15vw !important;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation:hover .navigation-content-last {
  display: block;
  position: absolute !important;
  top: 3rem !important;
}

.navigation2:hover .navigation-content-last2 {
  display: block;
  position: absolute !important;
  /* right: 225px !important;
  bottom: 45px !important; */
  transform: translate(-228px, -48px);
}

.navigation a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation2 a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation a:hover {
  background-color: #263997;
  color: white !important;
  border-radius: 4px;
}

.navigation2 a:hover {
  background-color: #263997;
  color: white !important;
  border-radius: 4px;
}

.keyboardArrow {
  rotate: -90deg;
  transition: ease all 0.2s;
}

.navigation:hover .keyboardArrow {
  rotate: 0deg;
  transition: ease all 0.2s;
}

.navigation2:hover .keyboardArrow {
  rotate: 0deg;
  transition: ease all 0.2s;
}

.navigation:hover .navigation-content {
  display: block;
}

.navigation2:hover .navigation-content {
  display: block;
}

.image--logo {
  width: 10%;
}

.logo--image {
  cursor: pointer !important;
}



#root>div>div.bg-light.nav--bar>div.d-flex.justify-content-around.align-items-end.w-100.border-bottom>div.searchStack.css-1jruifh-MuiStack-root {
  margin-bottom: 1rem !important;
}

.nav--bar {
  z-index: 99999;
  position: sticky !important;
  top: 0 !important;
}

.language-button {
  cursor: pointer;
}

.profile-btn {
  font-size: 30px !important;
  color: black !important;
}

.profile-btn-mobile {
  font-size: 14px !important;
  color: black !important;
}

.shoppingCart-mobile {
  color: #183696;

}

.username-button {
  margin-bottom: 1px !important;
  color: #08223c !important;
  font-size: 0.9rem;
}

#root>div.bg-light.nav--bar>div.d-flex.justify-content-around.w-100.border-bottom>div.searchStack.css-1jruifh-MuiStack-root>div>div>div {
  border-radius: 29px !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 30px !important;
}

.shoppingCart {
  font-size: calc(1.275rem + 0.3vw) !important;
  color: #294595 !important;
}

#root>div.bg-light.nav--bar>div.d-flex.justify-content-around.w-100.border-bottom>div.searchStack.css-1cvx1hr>div>div>div {
  border-radius: 29px !important;
  height: 7vh !important;
}

/* .MuiInputBase-root {
  border-radius: 8px !important;
}*/


#root>div>div.bg-light.nav--bar>div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom>div.col-5.css-ikzlcq>div>div>div {
  padding: 0px 10px 0px 0px !important;
}

@media only screen and (min-width: 601px) {
  .logo--image {
    width: 125px !important;
    height: auto;
    margin: 2px 0px 2px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .logo--image {
    max-height: 49px !important;
    width: auto;
    margin: 4px 0px 4px 0px;
  }

  .navigation-content {
    display: none !important;
  }

  .icon-none-sm {
    display: none !important;
  }

  .girl_position {
    object-position: -15px !important;
  }



  #root>div.bg-light.nav--bar>div:nth-child(2)>ul>li:nth-child(1)>div>a {
    padding: 0px !important;
  }

  .hover-underline-animation {
    padding: 0px !important;
  }

  #root>div.bg-light.nav--bar>div:nth-child(2)>ul>li.px-2.my-1>a>button {
    padding: 0px !important;
  }

  .profile-btn {
    font-size: 3rem !important;
    margin: 0 2px 0 2px;
    border: #184799 !important;
  }

  #root>div.d-flex {
    flex-direction: column !important;
  }

  #root>div:nth-child(2)>div>div.col-md-6.d-flex.flex-column.justify-content-center>h1:nth-child(1) {
    font-size: 2rem !important;
  }

  #root>div:nth-child(2)>div>div.col-md-6.d-flex.flex-column.justify-content-center>h1:nth-child(2) {
    font-size: 2rem !important;
  }

  #root>div:nth-child(2)>div>div.col-md-6.d-flex.flex-column.justify-content-center {
    align-items: center !important;
  }

  #root>div:nth-child(2)>div>div.col-md-6.position-relative>img {
    display: none !important;
  }

  #root>div:nth-child(3)>div>div.col-md-6.d-flex.flex-column.justify-content-center>h1:nth-child(1) {
    font-size: 2rem !important;
    text-align: center !important;
  }

  #root>div:nth-child(3)>div>div.col-md-6.d-flex.flex-column.justify-content-center>h1.mb-3 {
    font-size: 2rem !important;
    text-align: center !important;
  }

  #root>div:nth-child(3)>div>div.col-md-6.d-flex.flex-column.justify-content-center>p {
    width: 100% !important;
  }

  #root>div:nth-child(4)>h1 {
    font-size: 2rem !important;
  }

  #root>div.container>div>div.col-md-5.my-5.d-flex.align-items-center>div>h1 {
    font-size: 2rem !important;
  }

  #root>div.container>div>div.col-md-5.my-5.d-flex.align-items-center>div>h6 {
    font-size: 1.5rem !important;
  }

  #root>div.container>div>div.col-md-7.position-relative>img:nth-child(2) {
    width: 90vw !important;
  }

  #root>div.position-relative.w-75.m-auto.my-5>div>form>div.d-flex.justify-content-center>button,
  .btn--joinUs {
    width: 100% !important;
    font-size: 14px !important;
  }

  /* .swiper--margin {
    margin-left: 4rem !important;
  } */

  .navigation {
    font-size: 10px !important;
  }

  .descover-new {
    font-size: 10px !important;
    margin-top: -6px !important;
  }
}


.btn--signin {
  background-color: #184799 !important;
  border-radius: 6px !important;
  border: solid 1px #184799 !important;
  width: fit-content !important;
  font-size: 1rem !important;
}

@media only screen and (max-width: 680px) {
  .shoppingCart {
    font-size: 0.8rem !important;
    margin-left: 0.2rem !important;
  }

  .btn--signin {
    font-size: 0.8rem !important;
    min-width: 23vw !important;
  }

  .account-circle {
    font-size: 0.6rem !important;
  }

  .profile-btn {
    width: 10vw !important;
  }
}

.res-h1 {
  font-size: 20px !important;
}

.beta-version-header {
  background-color: #184799;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beta-version-animation {
  width: fit-content;
  margin: 0px;
  padding: 2px 0px 0px 0px;
}


@media only screen and (min-width: 600px) and (max-width:999px) {
  .navigation-content {
    max-width: 40vw !important;
    min-width: 40vw !important;
    min-height: 50vw !important;
    max-height: 50vw !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width:1250px) {
  .navigation-content {
    max-width: 40vw !important;
    min-width: 40vw !important;
    min-height: 35vh !important;
    max-height: 35vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

.mobile-view-button-text {
  font-size: 11px !important;

}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.icon-color {
  color: #183696 !important;
}

.icon-arrow-width {
  font-size: 12px !important;
}

.dropdown-list-menuItem {
  height: 30vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100vw !important;
  font-size: 17px !important;
}

.active-nav {
  background-color: #183696 !important;
  padding: 10px;
  border-radius: 10px;
  color: #f1f1f1 !important;
}

.active-nav .icon-color,
.active-nav .icon-color-text {
  color: #f1f1f1 !important;
}

.active-icon-2 {
  display: none;
}

.active-nav .active-icon-2 {
  display: flex;
}


.active-nav .active-icon-1 {
  display: none;
}

/* .searchbar-mobile {
  overflow: hidden !important;
} */

.icon-nav-burger {
  font-size: 2rem !important;
}

.icon-color-text {
  color: #526581;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xxees4 .MuiOutlinedInput-root {
  padding-right: none !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xxees4 .MuiAutocomplete-inputRoot {
  padding-right: none !important;
}

.MuiAutocomplete-hasPopupIcon.css-xxees4 .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-xxees4 .MuiOutlinedInput-root {
  padding-right: none !important;
}

.MuiAutocomplete-hasPopupIcon.css-xxees4 .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon.css-xxees4 .MuiAutocomplete-inputRoot {
  padding-right: none !important;
}

.css-xxees4 .MuiOutlinedInput-root {
  padding: 0px !important;
  background-color: #fff !important;
  border-radius: 10px !important;
}


.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1p5q5e5-MuiStack-root,
.css-1p5q5e5-MuiStack-root {
  background-color: #fff !important;
  border-radius: 6px !important;
  overflow: hidden !important;
  height: 6vh !important;
  margin-bottom: 5px !important;
}

.font-size-h1 {
  font-size: 18px;
}

.fixed-arrow {
  position: fixed;
  width: 4px;
  height: 50px;
  background-color: #08223c;
  border-radius: 20px;
}

.dropdown-list-menuItem::-webkit-scrollbar {
  display: none;
}

.mobile-div-navbar {
  font-family: "Tajawal", sans-serif !important;

}

#basic-menu>div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper>ul>li:nth-child(1) {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.253) !important;

}

#root>div>div.bg-light.nav--bar>div.d-flex.align-items-center.w-100.border-bottom>div.MuiStack-root.col-5.css-1p5q5e5-MuiStack-root>div>div>div {
  border: 1px solid rgba(0, 0, 0, 0.253) !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.253) !important;
}

.btn-outline-primary:hover {
  background-color: #184799 !important;
  border: 1px solid #184799 !important;
  color: white !important;
}

.btn-outline-primary {
  border: 1px solid #184799 !important;
  color: #184799 !important;
}

#basic-menu>div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper>ul {
  padding: 10px !important;
}